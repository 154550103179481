/*@import "index.css";*/

body {
    background-color: rgb(var(--color-page-background));
    background-image: url("assets/bg/black-felt.png");
    background-blend-mode: color-dodge;
}

.App {
    text-align: center;
    height: calc(100% - 2rem);
    color: rgb(var(--color-body-text));
}

@keyframes flip-first-half {
    from {
        transform: translateX(-5px) translateY(-5px) rotateY(0deg)
    }

    to {
        transform: translateX(-10px) translateY(-10px) rotateY(90deg);
    }
}

@keyframes flip-second-half {
    from {
        transform: translateX(-10px) translateY(-10px) rotateY(-90deg)
    }

    to {
        transform: translateX(0px) translateY(0px) rotateY(0deg);
    }
}

.animate-first-half {
    animation-duration: 100ms;
    animation-name: flip-first-half;
    animation-timing-function: ease-in;
}

.animate-second-half {
    animation-duration: 100ms;
    animation-name: flip-second-half;
    animation-timing-function: ease-out;
}
