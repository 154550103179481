@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        --color-known-letter: 255, 255, 255;
    }

    [data-color-blind="off"] {
        --color-correct: 22, 163, 74;
        --color-close: 234, 179, 8;
        --color-wrong: 31, 41, 55;
    }

    [data-color-blind="on"] {
        --color-correct: 255, 150, 97;
        --color-close: 7, 202, 199;
        --color-wrong: 18, 24, 33;
    }

    [data-dark-mode="on"] {
        --color-unknown: 75, 85, 99;
        --color-unknown-letter: 255, 255, 255;
        --color-body-text: 255, 255, 255;
        --color-page-background: 40, 44, 52;

        --toastify-color-dark: rgba(20, 22, 26, .8) !important;

    }

    [data-dark-mode="off"] {
        --color-unknown: 232, 234, 237;
        --color-unknown-letter: 0, 0, 0;
        --color-body-text: 0, 0, 0;
        --color-page-background: 231, 238, 253;

        --toastify-color-dark: rgba(231, 238, 253, .8) !important;
        --toastify-text-color-dark: black !important;
    }

    [data-fun-font="on"] {
        --font-tiles: "Itim", cursive;
    }

    [data-fun-font="on"] {
        --font-tiles: "Itim", cursive;
    }

    [data-fun-font="off"] {
        --font-tiles: sans-serif;
    }

    [data-cant="on"] {
        --cant-multiplier: 1.0;
    }

    [data-cant="off"] {
        --cant-multiplier: 0.0;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    @apply underline
}
